import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAccount } from "../services/account/account.service";

const PrivateRoute = ({ children }) => {
  localStorage.setItem('IsQuiz', false.toString());

  const accountId = localStorage.getItem('accountId');
  const authToken = localStorage.getItem('authToken');
  const headers = {
    'Authorization': `${authToken}`,
    'Content-Type': 'application/json',
    'accountId': localStorage.getItem('accountId')
  };
  // Define the method to be called after everything is done loading
  const afterLoading = async() => {
    console.log("PrivateRoute has finished loading");
    // Add any additional logic you want to execute after loading is done
    
    let response = await getAccount({"accountId": accountId}, headers);
    if (!response.status === 200) {
      throw new Error('Network response was not ok');
    }
    let tokenCount = response.data.account.Token;
    console.log('response tokens', tokenCount);
    localStorage.setItem('tokenCount', tokenCount);
  };

  // Use useEffect to call afterLoading after the component has rendered
  useEffect(() => {
    afterLoading();
  }, []);

  return authToken ? children: <Navigate to="/login" />;
};

export default PrivateRoute;