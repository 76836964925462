import { PostFormData } from "../../utils/http-client";
import axios from "axios";

// Function to call the FastAPI backend to get the most recent estimate PDF
const api = axios.create({
    baseURL: 'http://localhost:8000'
  });
  
export const getEstimate = async (headers) => {
    try {
      const response = await api.get(`/getEstimate`, { headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching estimate PDF:", error);
      throw error;
    }
  };
  
export const uploadPDF = function (data,headers) {
    return PostFormData(`/generateFromPDF`, 'POST', data, true,headers);
}

export const uploadAdjusterMail = function (data,headers) {
    return PostFormData(`/generateFromEmail`, 'POST', data, true,headers);
}
export const generateMailfromPDF = function (data,headers) {
    return PostFormData(`/emailPDF`, 'POST', data, true,headers);
}
export const generateMailfromText = function (data,headers) {
    return PostFormData(`/emailText`, 'POST', data, true,headers);
}
export const generateEstimate = function (data,headers) {
    return PostFormData(`/generateEstimate`, 'POST', data, true,headers);
}
export const generateAudioFile = function (data,headers) {
    return PostFormData(`/getAudioFile`, 'POST', data, true,headers);
}