import React, { useState, useEffect } from "react";
import { Button, Col, Container, Image, Nav } from "react-bootstrap";
import { getEstimate, generateEstimate } from "../services/uploads/upload.service";
import logo from "../assets/logo-2.png";
import FileUploader from "./FileUploader";
import { toast } from "react-toastify";

const Estimate = ({currentStep, goToNextStep}) => {
  const [estimatePdf, setEstimatePdf] = useState(null); // For uploaded PDFs
  const [pdfData, setPdfData] = useState(null);         // PDF content from the API
  const [fileName, setFileName] = useState(null);       // File name for downloaded PDF
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const tokenCount = localStorage.getItem("tokenCount");
  const headers = {
    "Authorization": localStorage.getItem("authToken"),
  };

  // Fetch the most recent PDF from the backend
  const fetchEstimate = async () => {
    setLoading(true);
    try {
      const response = await getEstimate(headers);
      if (response.content) {
        setPdfData(response.content);       // Set the PDF content
        setFileName(response.filename);     // Set the file name
      } else {
        setError("No PDF available to download.");
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching estimate PDF:", err);
      setError("Error fetching PDF. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstimate();
  }, []);  // Fetch the PDF once when the component loads

  // Display the PDF content or error message
  const renderContent = () => {
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (pdfData && fileName) {
    // Convert base64 to binary Blob
    const binary = atob(pdfData);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(array)], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);

    return (
      <div>
        <h5>{fileName}</h5>
        <iframe
          title="Estimate PDF"
          src={blobUrl}
          width="100%"
          height="600px"
          style={{ border: "none" }}
        />
      </div>
    );
  }

  return <p>No content available.</p>;
};


  const handlePdfNext = async () => {
    const toastId = toast.loading("Generating Summary of your argument"); // Show loading toast
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("estimate_pdf", estimatePdf);

      const response = await generateEstimate(formData, headers);
      setLoading(false);
      toast.update(toastId, { render: "Summary of your argument generated successfully", type: "success", isLoading: false, autoClose: 5000 });
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
      toast.update(toastId, { render: "Error generating summary. Try again later", type: "error", isLoading: false, autoClose: 5000 });
    }
  };

  const handlePdfNext2 = async () => {
    const toastId = toast.loading("Generating Summary of your argument"); // Show loading toast
    goToNextStep(); // Call the function to go to the next step

  };

  return (
    <Container className="my-5 d-flex justify-content-center flex-column align-items-center">
      <Col
        lg={6}
        className="mx-auto text-center mb-3"
        style={{
          borderBottom: "1px solid",
          borderColor: "rgb(255, 255, 255,0.2)",
        }}
      >
        <Image src={logo} />
        <h1 className="mt-5 mb-4">UPLOAD FILES</h1>
        <h5 className="mt-5 mb-4">Cortex Tokens: {tokenCount}</h5>
      </Col>

      <Nav variant="tabs" className="mb-4 home-nav">
        <Nav.Item>
          <Nav.Link active={true} className="me-2">
            Upload PDFs
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <FileUploader
        title="Upload Files of Property Estimates to Generate an Xactimate Equivalent Estimate"
        fileType="application/pdf"
        category="property"
        setFile={setEstimatePdf}
        file={estimatePdf}
      />

      <Col lg={10} className="mx-auto my-4 d-flex justify-content-center align-items-center">
        <Button className="step-button border-0 px-4 py-2 mx-auto" onClick={handlePdfNext}>
          <p className="m-0">Generate Estimate</p>
        </Button>
      </Col>
      <Col lg={10} className="mx-auto my-4 justify-content-end align-items-center d-flex">
        {currentStep !== 2 && (
          <Button
            className="step-button border-0 px-4 py-2"
            onClick={handlePdfNext2}

          >
            <p className="m-0">NEXT STEP</p>
          </Button>
          
        )}
      </Col>
    </Container>
  );
};

export default Estimate;
